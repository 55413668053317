import React from 'react'
import styled from 'styled-components'
import { SimpleHeading } from 'components/atoms'
import { LessonItem, LessonItemProps } from '../primary/secret1/lessonItem'
import {
  rem,
  color,
  innerWidth,
  breakpoints,
  fontFamily,
} from 'variables/_index'

export interface ThreeContentBoxProps {
  readonly lessonItems: Array<LessonItemProps>
}

type ThreeContentBoxPropsClassName = ThreeContentBoxProps & {
  readonly className: string
}

function Component({ className, lessonItems }: ThreeContentBoxPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <SimpleHeading
          mainText="3つのコンテンツ"
          subText="使い方自由自在！"
          headingLevel="h4"
        />
      </div>
      <div className="e-body">
        <div className="e-lesson-items">
          {lessonItems.map((lessonItem, index) => (
            <div className="e-lesson-item" key={index}>
              <LessonItem
                lineHeading={lessonItem.lineHeading}
                lead={lessonItem.lead}
                lessonBox={lessonItem.lessonBox}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<ThreeContentBoxPropsClassName>`
  > .e-head {
    text-align: center;
  }

  > .e-body {
    margin-top: ${rem(40 / 2)};
    padding-top: ${rem(60 / 2)};
    padding-bottom: ${rem(80 / 2)};
    background-color: #fff;

    @media ${breakpoints.lg} {
      margin-top: ${rem(36)};
    }

    > .e-lesson-items {
      > .e-lesson-item {
        &:nth-child(n + 2) {
          margin-top: ${rem(120 / 2)};
        }

        > .e-lesson-box {
          > .e-lesson-box-head {
          }

          > .e-lesson-box-lead {
            margin-top: ${rem(60 / 2)};
            padding-left: ${rem(50 / 2)};
            padding-right: ${rem(50 / 2)};
            line-height: 2;
          }

          > .e-lesson-box-sub-head {
            margin-top: ${rem(60 / 2)};
            padding-left: ${rem(50 / 2)};
            padding-right: ${rem(50 / 2)};
          }
        }
      }
    }
  }
`

export const ThreeContentBox: (props: ThreeContentBoxProps) => JSX.Element =
  StyledComponent

export default ThreeContentBox
