import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { SecretHead } from 'components/group/index'
import {
  rem,
  color,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'
import StarGirlLead from './starGirlLead'
import { IndividualStudyBox, IndividualStudyBoxProps } from './individualStudyBox'
import { ThreeContentBox, ThreeContentBoxProps } from './threeContentBox'

export interface Secret1SectionProps {
  readonly individualStudyBox: IndividualStudyBoxProps
  readonly threeContentBox: ThreeContentBoxProps
}

type Secret1SectionPropsClassName = Secret1SectionProps & {
  readonly className: string
}

function Component({
  className,
  individualStudyBox,
  threeContentBox,
}: Secret1SectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <SecretHead
          secretElement={
            <StaticImage
              src="../../../assets/images/junior/icon-secret1.png"
              alt="secret1 秘密"
            />
          }
          lead="「わからない」を発見！"
          title="成績があがる仕組み"
          headingLevel="h3"
        />
      </div>
      <div className="e-body">
        <div className="e-inner">
          <div className="e-secret1-items">
            <div className="e-secret1-item">
              <StarGirlLead />
            </div>
            <div className="e-secret1-item">
              <IndividualStudyBox
                lead={individualStudyBox.lead}
                lineHeading={individualStudyBox.lineHeading}
                lessonBoxes={individualStudyBox.lessonBoxes}
              />
            </div>
            <div className="e-secret1-item">
              <ThreeContentBox lessonItems={threeContentBox.lessonItems} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<Secret1SectionPropsClassName>`
  padding-top: ${rem(80 / 2)};
  padding-bottom: ${rem(160 / 2)};

  > .e-body {
    margin-top: ${rem(80 / 2)};
    padding-top: ${rem(120 / 2)};
    padding-bottom: 1.875rem;
    background-color: ${color.mainBackground};

    > .e-inner {
      width: ${innerWidth.sm};
      max-width: 100%;
      margin-top: ${rem(40 / 2)};
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${innerWidth.md};
        padding-left: ${rem(innerPadding.md + innerDummyPadding.md)};
        padding-right: ${rem(innerPadding.md + innerDummyPadding.md)};
      }

      > .e-secret1-items {
        > .e-secret1-item {
          &:nth-child(n + 2) {
            margin-top: ${rem(160 / 2)};
          }
        }
      }
    }
  }
`

export const Secret1Section: (props: Secret1SectionProps) => JSX.Element = StyledComponent

export default Secret1Section
