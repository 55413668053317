import React from 'react'
import Seo from 'components/seo'
import Layout from 'layout/base'
import PrimaryCourseTemplate from 'templates/primaryCourseTemplate'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface CoursePrimaryPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const CoursePrimaryPage = ({ pageContext, location }: CoursePrimaryPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'course'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`小学生コース${globalTitle(location.pathname)}`}
        description="小3～小6のお子さまが対象。中学に向けて、⾃ら勉強する習慣が定着し、必要な基礎学力が身につくコースです。"
        path={location.pathname}
      />
      <PrimaryCourseTemplate />
    </Layout>
  )
}

export default CoursePrimaryPage
