import React from 'react'
import styled from 'styled-components'
import { StarGirl } from 'components/group/index'
import { rem, color, innerWidth, breakpoints } from 'variables/_index'

export interface StarGirlLeadProps {
  readonly noProps?: string
}

type StarGirlLeadPropsClassName = StarGirlLeadProps & {
  readonly className: string
}

function Component({ className }: StarGirlLeadPropsClassName) {
  return (
    <div className={className}>
      <div className="e-image">
        <StarGirl />
      </div>
      <div className="e-lead">
        <span className="e-em">個別指導</span>と
        <span className="e-em">3つのコンテンツ</span>を<br />
        ⾃由に組み合わせて、
        <br />
        自分だけのカリキュラムがつくれます。
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StarGirlLeadPropsClassName>`
  padding: ${rem(60 / 2)} ${rem(30 / 2)};
  background-color: #fff;

  > .e-image {
  }

  > .e-lead {
    margin-top: ${rem(40 / 2)};
    text-align: center;
    line-height: 2;

    > .e-em {
      color: ${color.main};
    }

    @media ${breakpoints.lg} {
    }
  }
`

export const StarGirlLead: (props: StarGirlLeadProps) => JSX.Element =
  StyledComponent

export default StarGirlLead
