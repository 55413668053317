import React from 'react'
import styled from 'styled-components'
import { LeadBoxLarge, LeadBoxLargeProps } from 'components/atoms'
import {
  rem,
  color,
  innerWidth,
  breakpoints,
  fontFamily,
} from 'variables/_index'

export interface LeadHeadSectionProps {
  readonly leadBoxLarge: LeadBoxLargeProps
}

type LeadHeadSectionPropsClassName = LeadHeadSectionProps & {
  readonly className: string
}

function Component({ className, leadBoxLarge }: LeadHeadSectionPropsClassName) {
  return (
    <div className={className}>
      <LeadBoxLarge
        leadText={leadBoxLarge.leadText}
        isBackground={leadBoxLarge.isBackground}
        headingLevel={leadBoxLarge.headingLevel}
      />
    </div>
  )
}

const StyledComponent = styled(Component)<LeadHeadSectionPropsClassName>`
  padding-top: ${rem(200 / 2)};
  text-align: center;
`

export const LeadHeadSection: (props: LeadHeadSectionProps) => JSX.Element =
  StyledComponent

export default LeadHeadSection
