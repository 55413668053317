import React from 'react'
import styled from 'styled-components'
import { LineHeading, LineHeadingProps, SimpleHeading } from 'components/atoms'
import { LessonBox, LessonBoxProps } from 'components/group'
import {
  rem,
  color,
  innerWidth,
  breakpoints,
  fontFamily,
} from 'variables/_index'

export interface IndividualStudyBoxProps {
  readonly lead: string
  readonly lineHeading: LineHeadingProps
  readonly lessonBoxes: Array<LessonBoxProps>
}

type IndividualStudyBoxPropsClassName = IndividualStudyBoxProps & {
  readonly className: string
}

function Component({
  className,
  lead,
  lineHeading,
  lessonBoxes,
}: IndividualStudyBoxPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <SimpleHeading
          mainText="個別スタディ"
          subText="一人ひとりに合わせた"
          headingLevel="h4"
        />
      </div>
      <div className="e-body">
        <div className="e-lead">{lead}</div>
        <div className="e-sub-head">
          <LineHeading
            mainText={lineHeading.mainText}
            subText={lineHeading?.subText}
            headingLevel={lineHeading?.headingLevel}
          />
        </div>
        <div className="e-lesson-items">
          {lessonBoxes.map((lessonBox, index) => (
            <div className="e-lesson-item" key={index}>
              <LessonBox
                headText={lessonBox.headText}
                bodyText1={lessonBox.bodyText1}
                bodyText2={lessonBox?.bodyText2}
                isAlignLeft={lessonBox?.isAlignLeft}
                headingLevel={lessonBox?.headingLevel}
                headTextSub={lessonBox?.headTextSub}
                attentionText={lessonBox?.attentionText}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<IndividualStudyBoxPropsClassName>`
  > .e-head {
    text-align: center;
  }

  > .e-body {
    margin-top: ${rem(40 / 2)};
    padding-bottom: ${rem(80 / 2)};
    background-color: #fff;

    @media ${breakpoints.lg} {
      margin-top: ${rem(36)};
    }

    > .e-lead {
      line-height: 2;
      padding: ${rem(60 / 2)} ${rem(50 / 2)};
    }

    > .e-sub-head {
      margin-top: ${rem(60 / 2)};
    }

    > .e-lesson-items {
      margin-top: ${rem(60 / 2)};
      padding-left: ${rem(50 / 2)};
      padding-right: ${rem(50 / 2)};

      > .e-lesson-item {
        &:nth-child(n + 2) {
          margin-top: ${rem(40 / 2)};
        }
      }
    }
  }
`

export const IndividualStudyBox: (
  props: IndividualStudyBoxProps
) => JSX.Element = StyledComponent

export default IndividualStudyBox
