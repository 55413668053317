import React from 'react'
import styled from 'styled-components'
import { LineHeading, LineHeadingProps } from 'components/atoms'
import { LessonBox, LessonBoxProps } from 'components/group'
import { rem, color, fontFamily } from 'variables/_index'

export interface LessonItemProps {
  readonly lineHeading: LineHeadingProps
  readonly lead: string
  readonly lessonBox: LessonBoxProps
}

type LessonItemPropsClassName = LessonItemProps & {
  readonly className: string
}

function Component({
  className,
  lineHeading,
  lead,
  lessonBox,
}: LessonItemPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <LineHeading
          mainText={lineHeading.mainText}
          subText={lineHeading?.subText}
          headingLevel={lineHeading?.headingLevel}
        />
      </div>
      <div className="e-lead">{lead}</div>
      <div className="e-box">
        <LessonBox
          headText={lessonBox.headText}
          headTextSub={lessonBox.headTextSub}
          bodyText1={lessonBox.bodyText1}
          headingLevel={lessonBox?.headingLevel}
          attentionText={lessonBox?.attentionText}
        />
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<LessonItemPropsClassName>`
  > .e-head {
  }

  > .e-lead {
    margin-top: ${rem(60 / 2)};
    padding-left: ${rem(50 / 2)};
    padding-right: ${rem(50 / 2)};
    line-height: 2;
  }

  > .e-box {
    margin-top: ${rem(60 / 2)};
    padding-left: ${rem(50 / 2)};
    padding-right: ${rem(50 / 2)};
  }
`

export const LessonItem: (props: LessonItemProps) => JSX.Element =
  StyledComponent

export default LessonItem
