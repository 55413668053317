import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import HeadSection from 'components/section/course/headSection'
import LeadHeadSection from 'components/section/course/leadHeadSection'
import Secret1Section from 'components/section/course/secret1Section'
import Secret2Section from 'components/section/course/secret2Section'
import PriceSection from 'components/section/course/priceSection'
import StyleSection from 'components/section/styleSection'
import CallToActionSection from 'components/section/common/callToActionSection'
import { RoomStudy, RoomTeach, RoomTest, RoomTeacher } from 'components/icon/index'
import {} from 'variables/_index'

export interface PrimaryCourseTemplateProps {
  readonly noProps?: string
}

type PrimaryCourseTemplatePropsClassName = PrimaryCourseTemplateProps & {
  readonly className: string
}

function Component({ className }: PrimaryCourseTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection
        imageElement={
          <StaticImage src="../assets/images/primary/mv.jpg" alt="" placeholder="none" />
        }
        title="小学生コース"
        year="小３〜小６"
        leadElement={
          <>
            ⾃ら勉強する習慣が定着し、
            <br />
            必要な基礎学力が身につきます。
          </>
        }
      />
      <LeadHeadSection
        leadBoxLarge={{
          leadText: <>基礎学力が身につく</>,
          headingLevel: 'h2',
        }}
      />
      <Secret1Section
        individualStudyBox={{
          lead: '講師がお子さまと理解度を確認しながら行う予習型授業。一番大切な学校の授業が理解しやすくなります。宿題も出るので復習もバッチリ。',
          lineHeading: {
            mainText: '授業の概要',
            headingLevel: 'h5',
          },
          lessonBoxes: [
            {
              headText: <>教科</>,
              bodyText1: <>算数｜英語｜国語</>,
            },
            {
              headText: <>時間割</>,
              bodyText1: <>50分｜講師1人・生徒2人</>,
              bodyText2: (
                <>
                  月～土曜日
                  <br />
                  1｜ 17：00～17：50
                  <br />
                  2｜ 18：00～18：50
                  <br />
                  3｜ 19：00～19：50
                  <br />
                  4｜ 20：00～20：50
                  <br />
                  5｜ 21：00～21：50
                </>
              ),
              isAlignLeft: true,
            },
          ],
        }}
        threeContentBox={{
          lessonItems: [
            {
              lineHeading: {
                mainText: '1｜集団ライブ授業',
                subText: '',
                headingLevel: 'h5',
              },
              lead: '学習したい内容を自由に選び、思う存分受講が可能！楽しく「学ぶ」・「考える」・「表現する」工夫がほどこされた授業です。',
              lessonBox: {
                headText: <>算数｜英検&reg;対策｜英会話</>,
                bodyText1: <>1回 20〜45分｜回数無制限</>,
              },
            },
            {
              lineHeading: {
                mainText: '2｜映像授業',
                subText: '',
                headingLevel: 'h5',
              },
              lead: 'イラストや穴埋め式を取り入れた、見やすくわかりやすい授業動画。各単元のポイント説明から問題の解き方までを細かく解説しています。',
              lessonBox: {
                headText: (
                  <>
                    算数｜国語｜英検&reg;対策
                    <span className="u-hidden-md">｜</span>
                    <br className="u-hidden-lg" />
                    理科｜社会
                  </>
                ),
                headTextSub: <>※小3は算数・国語・英検&reg;対策</>,
                bodyText1: <>他学年の授業も視聴OK</>,
              },
            },
            {
              lineHeading: {
                mainText: '3｜クイックテスト',
                subText: '',
                headingLevel: 'h5',
              },
              lead: '隙間時間に取り組める、クイズ形式のWEBテスト。テスト前の確認や授業のふり返りに役立ちます。',
              lessonBox: {
                headText: <>算数｜英語｜国語｜理科｜社会</>,
                bodyText1: <>他学年の授業も視聴OK</>,
                headTextSub: <>※小3は算数・国語・英検&reg;対策</>,
                attentionText: <>※英検&reg;は、公益財団法人 日本英語検定協会の<br className="u-hidden-pc" />登録商標です。</>
              },
            },
          ],
        }}
      />
      <CallToActionSection contactId="cta_content1_trial" isDisplayDocument={false} />
      <Secret2Section
        netRooms={{
          netRoomItems: [
            {
              lineHeading: {
                mainText: '1｜スタディルーム',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomStudy />,
              },
              time: '月～土・18:00～21:40',
              description: '宿題やテスト勉強ができる自習室。いつも先生が見守っています。',
            },
            {
              lineHeading: {
                mainText: '2｜解説ルーム',
                subText: '（予約制）',
                headingLevel: 'h5',
              },
              iconBox: {
                imageElement: <RoomTeach />,
              },
              description: '勉強でわからないところを先生に質問できます。学校の宿題もOK。',
            },
          ],
        }}
      />
      <CallToActionSection contactId="cta_content2_trial" isDisplayDocument={false} />
      <PriceSection
        priceBoxes={[
          {
            headText: '小学３年生',
            bodyText1: <>週1回｜11,000円／月</>,
          },
          {
            headText: '小学４～６年生',
            bodyText1: <>週1回｜13,200円／月</>,
            leadText: '集団ライブ授業・映像授業・クイックテストは上記金額に含まれます。',
            subTexts: ['※ 教材費 2,400円〜', '※諸経費 2,420円／月'],
          },
        ]}
        isParallax={true}
      />
      <StyleSection />
    </div>
  )
}

const StyledComponent = styled(Component)<PrimaryCourseTemplatePropsClassName>``

export const PrimaryCourseTemplate: (props: PrimaryCourseTemplateProps) => JSX.Element =
  StyledComponent

export default PrimaryCourseTemplate
